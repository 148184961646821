import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"header"},[_c('h1',{staticClass:"heading"},[_vm._v(_vm._s(_vm.$t('objects["Копировать объект"]')))])]),_c(VForm,{ref:"form",staticClass:"timeline",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"timeline__item timeline__item_hotel"},[_c('h2',{staticClass:"timeline__label"},[_vm._v(_vm._s(_vm.$t('objects["Данные объекта"]')))]),_c('div',{staticClass:"timeline__value"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Copy from")]),_c(VSelect,{attrs:{"solo":"","outlined":"","flat":"","placeholder":_vm.$t('objects[\'Выбрать объект\']'),"items":_vm.objects.list,"item-value":"id","item-text":"name","return-object":""},on:{"change":function($event){return _vm.updateSelectedObject(_vm.selectedObject)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.selectedObject),callback:function ($$v) {_vm.selectedObject=$$v},expression:"selectedObject"}})],1)]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v("Name of new facility")]),_c(VTextField,{attrs:{"solo":"","outlined":"","flat":"","label":_vm.$t('objects[\'Название\']'),"rules":_vm.nameRules},model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}})],1)]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v(" Address of new facility ")]),_c(VTextField,{attrs:{"solo":"","outlined":"","flat":"","placeholder":_vm.$t('objects[\'Адрес\']'),"rules":_vm.addressRules},model:{value:(_vm.object.address),callback:function ($$v) {_vm.$set(_vm.object, "address", $$v)},expression:"object.address"}})],1)]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('p',{staticClass:"tip"},[_vm._v(" "+_vm._s(_vm.$t( 'objects["После добавления нового объекта стоимость подписки изменится"]' ))+" ")])])])])]),_c('div',{staticClass:"timeline__item timeline__item_tariff"},[_c('h2',{staticClass:"timeline__label"},[_vm._v(_vm._s(_vm.$t('objects["Тарифный план"]')))]),_c('div',{staticClass:"timeline__value"},[_c('div',{staticClass:"field"},[_c('ObjectTariff',{attrs:{"current":+_vm.selectedObject.tariff_id},on:{"changeTariff":_vm.changeTariff}})],1),_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c(VBtn,{staticClass:"object__submit btn btn_primary",attrs:{"color":"primary","small":"","outlined":"","dense":""},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$t('objects["Копировать отель"]'))+" ")]),_c('PopupEmail',{ref:"PopupEmail",attrs:{"title":_vm.$t('popup.tariff_email', { email: 'me@bs.hr' })}})],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }