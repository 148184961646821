<template>
  <main >
    <div class="header">
      <h1 class="heading">{{ $t('objects["Копировать объект"]') }}</h1>
    </div>
    <v-form class="timeline" ref="form" v-model="valid">
      <div class="timeline__item timeline__item_hotel">
        <h2 class="timeline__label">{{ $t('objects["Данные объекта"]') }}</h2>
        <div class="timeline__value">
          <div class="field">
            <div class="control">
                <label class="label">Copy from</label>
              <v-select
                solo
                outlined
                flat
                :placeholder="$t('objects[\'Выбрать объект\']')"
                :items="objects.list"
                item-value="id"
                item-text="name"
                return-object
                v-model="selectedObject"
                @change="updateSelectedObject(selectedObject)"
              >
                <template slot="selection" slot-scope="{ item }">
                  {{ item.name }}
                </template>
                <template slot="item" slot-scope="{ item }">
                  {{ item.name }}
                </template>
              </v-select>
            </div>
          </div>
          <div class="field">
            <div class="control">
                <label class="label">Name of new facility</label>
              <v-text-field
                solo
                outlined
                flat
                :label="$t('objects[\'Название\']')"
                :rules="nameRules"
                v-model="object.name"
              ></v-text-field>
            </div>
          </div>
          <div class="field">
            <div class="control">
                <label class="label"> Address of new facility </label>
              <v-text-field
                solo
                outlined
                flat
                :placeholder="$t('objects[\'Адрес\']')"
                :rules="addressRules"
                v-model="object.address"
              ></v-text-field>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <p class="tip">
                {{
                  $t(
                    'objects["После добавления нового объекта стоимость подписки изменится"]'
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="timeline__item timeline__item_tariff">
        <h2 class="timeline__label">{{ $t('objects["Тарифный план"]') }}</h2>
        <div class="timeline__value">
          <div class="field">
            <ObjectTariff @changeTariff="changeTariff" :current="+selectedObject.tariff_id" />
          </div>
          <div class="field">
            <div class="control">
              <v-btn
                class="object__submit btn btn_primary"
                color="primary"
                small
                outlined
                dense
                @click="validate"
              >
                {{ $t('objects["Копировать отель"]') }}
              </v-btn>

              <PopupEmail
                ref="PopupEmail"
                :title="$t('popup.tariff_email', { email: 'me@bs.hr' })"
              />
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </main>
</template>

<script>
import ObjectTariff from "@/components/ObjectTariff.vue";
import PopupEmail from "@/components/Popup/PopupEmail.vue";
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "Objects",
  beforeRouteEnter(to, from, next) {
      if(!store.state.objects.list.length){
          store.dispatch('objects/getList')
              .then((data) => {
                  next();
              }).catch(() => {
              next();
          })
      }else{
          next();
      }
  },
  components: {
    ObjectTariff,
    PopupEmail,
  },
  computed: {
    ...mapState(["objects"]),
  },
  mounted(){
    let objects = this.objects.list;
    let object = objects.find(object => object.id === +this.$route.params.id);
    this.selectedObject = JSON.parse(JSON.stringify(object));
    this.object.name = this.selectedObject.name;
    this.object.address = this.selectedObject.address;
    this.object.tariff_id = this.selectedObject.tariff_id;
    this.object.id = this.selectedObject.id;
    this.tariff_id = this.selectedObject.tariff_id;
  },
  data() {
    return {
      valid: false,
      nameRules: [(v) => !!v || this.$t('objects["Название обязательно"]')],
      addressRules: [(v) => !!v || this.$t('objects["Адрес обязателен"]')],
      object: {
        id:null,
        name: null,
        address: null,
        tariff_id: 1,
        lang: this.$route.params.lang || "en",
      },
      selectedObject:{
        id:null,
        name:null,
        address:null,
        tariff_id:1,
        lang:this.$route.params.lang || "en",
      },
      tariff_id: 1,
    };
  },
  created() {


  },
  methods: {
    objectCreate(e) {
      e.preventDefault();
    },
    updateSelectedObject(obj){
      this.object.name = obj.name;
      this.object.address = obj.address;
      this.object.tariff_id = obj.tariff_id;
      this.object.id = obj.id;
    },
    validate() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$store
          .dispatch("objects/clone", { ...this.object })
          .then(async (object_id) => {
              await this.$router.push({
                  name: "objects",
                  params: { lang: this.$route.params.lang, new: true },
              });
          });
      } else {
        this.$nextTick(() => {
          const el = this.$el.querySelector(
            ".v-messages.error--text:first-of-type"
          );
          this.$vuetify.goTo(el, {
            offset: 100,
          });
          return;
        });
      }
    },
    changeTariff(tariff_id) {
      this.tariff_id = tariff_id;
      this.object.tariff_id = tariff_id;
    },
  },
};
</script>

<style lang="scss">
.object__submit {
  max-width: 255px;
  width: 100%;
}
</style>
